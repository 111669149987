p{
    margin-bottom:0!important;
}
.app-content{
    box-sizing: border-box;
}
.item-project{
    position: relative;
    display: inline-block;
    background:#fff;
    width: 280px;
    height: 230px;
    box-shadow: 2.5px 4.33px 3.64px 0.36px rgba(5,2,2,0.11);
    border-radius:5px;
    -webkit-border-radius:5px;
    -moz-border-radius:5px;
    -ms-border-radius:5px;
    -o-border-radius:5px;
    margin:0 10px;
    margin-bottom:20px;
}
.item-project>.project-photo{
    height: 130px;
    width: 100%;
    position: relative;
    overflow: hidden;
}
.item-project>.project-photo>img{
    width: 100%;
}
.item-project>.project-photo>span{
    position: absolute;
    top:0px;
    right:0px;
    padding: 2px 5px;
    border-radius:5px;
    -webkit-border-radius:5px;
    -moz-border-radius:5px;
    -ms-border-radius:5px;
    -o-border-radius:5px;
    color: #fff;
    font-size:14px;
}
/* 未上线样式 */
.item-project>.project-photo>span.not-online{
    background:#FFA302;
}
/* 进行中样式 */
.item-project>.project-photo>span.underway{
    background:#32B16C;
}
/* 已结束样式 */
.item-project>.project-photo>span.finished{
    background:#959595;
}
.item-project>.project-content{
    padding: 0 10px;
}
.item-project>.project-content>.project-name{
    font-size: 20px;
    font-weight: bold;
    line-height: 50px;
    cursor: pointer;
    word-break: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}
.item-project>.project-content>.project-bonus{
    color:#FF4747;
    font-size:14px;
    line-height: 25px;
}
.item-project>.project-content>.project-time{
    font-size:14px;
    line-height: 20px;
}
.item-project>.project-detail{
    position: absolute;
}
.item-project>.project-detail{
    position: absolute;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    background:rgba(0,0,0,.8);
    color:#fff;
    padding:10px;
    display: none;
    /* cursor: pointer; */
}
.item-project:hover .project-detail{
    display: block;
}
.item-project>.project-detail>.project-name{
    font-size:18px;
    line-height: 30px;
    cursor: pointer;
    word-break: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.item-project>.project-detail>.project-bonus{
    color:#FF4747;
    font-size:14px;
    line-height: 25px;
}
.item-project>.project-detail>.project-time{
    font-size:14px;
    line-height: 20px;
}
.item-project>.project-detail>.intro{
    font-size: 12px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    max-height: 130px;
}
.success-alert{
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
}
.error-alert{
    background-color: #fff1f0;
    border: 1px solid #ffa39e;
}
.warn-alert{
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
}
.rankin-list{
    background: url('../images/backdrop.png') no-repeat;
    background-size: 100%;
}
.w-e-up-btn>i{
    font-size: 100%;
}
.no-notice{
    text-align: center;
    line-height: 100px;
    font-size: 20px;
    color: #999;
}

.editerTable table{
    width: 100%;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
}
.editerTable table tr th,.editerTable table tr td{
    white-space: pre-wrap;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 3px 5px;
    text-align: center;
}
.editerTable table tr th img,.editerTable table tr td img {
    width: 100% !important;
}
