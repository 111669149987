.page{
    text-align: center;
    height: 56px;
    margin:20px 0;
}
.page button{
    vertical-align: middle;
    height: 25px;
    line-height: 18px;
    background: transparent;
    border:1px solid #eee;
    border-radius: 2px;
    margin:0 5px;
    color: #999;
    outline: none;
    font-size: 14px;
    padding:0 8px;
    transition:all 0.1s;
    font-family: "微软雅黑";
    cursor: pointer;
    background-color:#fff;
}
.page button:hover {
    background: #7260F7;
    color:#fff;
}
.pagenum .active{
    background: #7260F7;
    color:#fff;
}
.page .disabled{
    background: #eee;
    color:#333;
    /* border:1px solid #bbb; */
    cursor: not-allowed;
}
.page .disabled:hover{
    background: #eee;
    color:#333;
    /* border:1px solid #bbb; */
    cursor: not-allowed;
}
.pagenum .etc{
    height: 100%;
    padding:0 3px;
    color:#666;
    background: transparent;
    border:none;
}
.allnum,
.jumpnum{
    font-family: 'Franklin Gothic Medium',
    'Arial Narrow',
    Arial,
    sans-serif;
    margin:0 10px;
    font-size: 14px;
    color:#999b9d;
}
.fa-angle-right,
.fa-angle-left {
    display: block
}
.allnum{
    margin:0 5px;
}
.jumpnum{
    color:#999b9d;
}
.jumpnum input{
    width:40px;
    height: 18px;
    font-size: 14px;
    border:1px solid #7260F7;
    text-align: center;
    border-radius: 2px;
    background: transparent;
    margin:0 5px;
    color:#fff;
    transition:all 0.2s;
}
.jumpnum input:focus{
    border:1px solid #7260F7;
}
.every{
    font-size: 14px;
    color:#999b9d;
    margin-left:10px;
}
.every .everyxia{
    display: inline-block;
    margin:0 5px;
    width: 50px;
    height: 20px;
    font-size: 14px;
    border: 1px solid #7260F7;
    border-radius: 10px;
    color:#fff;
    position: relative;
}
.every .everyxia span{
    display: block;
    height: 20px;
    line-height: 20px;
    text-align: left;
    padding-left: 10px;
    position: relative;
    cursor: pointer;
}
.every .everyxia span i{
    position: absolute;
    color:#7260F7;
    right:4px;
    top:3px;
}
.e-xiala{
    width:100%;
    background: rgb(35, 39, 47);
    position: absolute;
    left:0;
    bottom:21px;
    border: 1px solid #7260F7;
    overflow: hidden;
    z-index:300;
    height:0;
    opacity:0;
    overflow:hidden;

}
.e-xiala span{
    display: block;
    height:20px;
    line-height: 20px;
    padding-left:10px;
    color:#fff;
    cursor: pointer;
}
.e-xiala span:hover{
    background: #7260F7;

}
.e-xiala .active{
    background: #7260F7;
}