.notice{
    width: 1200px;
    margin: 0 auto;
    padding-top:20px;
}
.notice-body{
    background:#fff;
    border-radius:5px;
}
.notice-header{
    line-height: 50px;
    border-bottom:1px solid #eee;
    padding-left: 10px;
}
.notice-header>span{
    display: inline-block;
    margin:0 20px;
    font-size:16px;
    color:#999;
    cursor: pointer;
    position: relative;
}
.notice-header>.active{
    color:#000;
}
.notice-header>.active>b{
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 2px;
    bottom:0px;
    left:0px;
    background-color:#7260F7
}
.notice-content{
    padding:20px;
    border-bottom:1px solid #eee;
}
.notice-content-header{
    color:#999;
    line-height:25px; 
    font-size:12px;
}
.notice-content-header>.title{
    font-size:16px;
    display: inline-block;
    margin-right: 10px;
    max-width:900px;
    white-space: nowrap;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align:top; 
}
.notice-content-header>.time{
    display: inline-block;
    vertical-align:bottom; 
    line-height: 30px;
    font-size: 12px;
}
.notice-content-detail>pre{
    word-break: break-all;
    white-space: pre-wrap;
}
.notice-content-detail>pre>span{
    font-size:15px;
    color:#7260F7;
    cursor: pointer;
    white-space: nowrap;
    display: inline-block;
}
/* .notice-page{
    background-color: #fff;
    line-height: 55px;
} */