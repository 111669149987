html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 14px;
  /* vertical-align: baseline; */
  background: transparent;
  font-family: "微软雅黑";
}

a {
  text-decoration: none;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}
.red-star{
    position: absolute;
    font-size:20px;
    color:#ff0101;
    top:4px;
    left:-14px;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
.huiCar{
  cursor: not-allowed;
}
/* remember to define focus styles! */

:focus {
  outline: 0;
}

/* remember to highlight inserts somehow! */

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

/* tables still need 'cellspacing="0"' in the markup */

table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  /* 只有定义了表格的布局算法为fixed，下面td的定义才能起作用。 */
}

td {
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  color: #333;
  min-width: 1200px;
  /* overflow-y: hidden; */
}

html body {
  background-color: #F1F0F0;
  min-width: 1200px;
}

input::-webkit-input-placeholder,
textarea ::-webkit-textarea-placeholder {
  color: #5f6a81;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #5f6a81;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #5f6a81;
}

input:-ms-input-placeholder,
textarea:-ms-textarea-placeholder {
  /* Internet Explorer 10-11 */
  color: #5f6a81;
}

button,input {
  outline: none;
  border: none;
}
*::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
}

*::-webkit-scrollbar-thumb {
  background: #7260F7;
}

*::-webkit-scrollbar-track {
  background: transparent;
}
/* 清除input  type=search的X */
input::-webkit-search-cancel-button{
  display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
input::-webkit-input-placeholder{
    color: #aaa;
    font-size: 14px;
}
textarea::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #aaa;
  font-size: 14px;
}
textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #aaa;
  font-size: 14px;
}
textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #aaa;
  font-size: 14px;
}
textarea::-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #aaa;
  font-size: 14px;
}